<template>
  <div
    :class="outerWrapperClasses"
    class="relative"
    :data-animate-type="animateType"
    :data-has-media-position="backgroundMediaYStartPosition !== ''"
  >
    <div
      v-if="row.fields.content && row.fields.content.length > 0"
      :class="innerWrapperClasses"
      class="relative z-30"
    >
      <div :class="rowEntryWrapperClasses">
        <template v-for="entry in row.fields.content" :key="entry.id">
          <ModularRowContentEntries :row="row" :entry="entry" />
        </template>
      </div>
    </div>
    <div
      v-if="!isVideo && row.fields.backgroundColor !== 'Overflow Image'"
      class="absolute top-0 block h-full w-full overflow-hidden"
    >
      <ImagesNuxtImg
        v-if="row.fields.backgroundImage.fields.image.fields.file"
        :url="row.fields.backgroundImage.fields.image.fields.file.url"
        class="rwbm-background-img absolute bottom-0 left-0 h-full w-full object-cover transition-all will-change-auto"
        :alt="
          row.fields.backgroundImage.fields.image.fields.alt
            ? row.fields.backgroundImage.fields.image.fields.alt
            : 'background img'
        "
        :preload="true"
      />
      <div
        v-if="bottomGradient"
        :style="{
          background: bottomGradient
            ? `linear-gradient(0deg, ${bottomGradient} 0%, rgba(17, 17, 17, 0.76) 20.3%, rgba(17, 17, 17, 0.51) 36.9%, rgba(0, 0, 0, 0.00) 92.25%);`
            : '',
        }"
        class="pointer-events-none absolute bottom-0 block h-[50%] w-full"
      ></div>
    </div>
    <div
      v-if="isVideo"
      class="hero--video absolute top-0 h-full w-full overflow-hidden"
    >
      <VideosTheVideo :video="row.fields.backgroundImage.fields" />
    </div>
    <div
      v-if="row.fields.backgroundColor === 'Overflow Image'"
      class="bg--image-overflow"
    >
      <ImagesNuxtImg
        v-if="row.fields.backgroundImage.fields.image.fields.file"
        :url="row.fields.backgroundImage.fields.image.fields.file.url"
        class="absolute bottom-0 h-full w-full object-cover"
        :alt="
          row.fields.backgroundImage.fields.image.fields.alt
            ? row.fields.backgroundImage.fields.image.fields.alt
            : 'background img'
        "
        :preload="true"
      />
      <div v-if="isVideo" class="hero--video top-0">
        <VideosTheVideo :video="row.fields.backgroundImage.fields" />
      </div>
    </div>
    <LinksContentfulLink v-if="row.sys.id" :id="row.sys.id" label="row" />
  </div>
</template>

<script setup>
  const props = defineProps({
    row: {
      type: Object,
      required: true,
    },
    isVideo: {
      type: Boolean,
      required: true,
    },
    innerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    outerWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
    rowEntryWrapperClasses: {
      type: String,
      required: false,
      default: '',
    },
  });

  let observer;

  const animateType = computed(() => {
    return props.row.fields?.jsonContent?.animate
      ? props.row.fields.jsonContent.animate
      : '';
  });

  const backgroundMediaYStartPosition = computed(() => {
    return props.row.fields?.jsonContent?.backgroundMediaYStartPosition
      ? props.row.fields.jsonContent.backgroundMediaYStartPosition
      : '';
  });

  const parallaxVelocity = computed(() => {
    return props.row.fields?.jsonContent?.parallaxVelocity
      ? props.row.fields.jsonContent.parallaxVelocity
      : 10;
  });

  const bottomGradient = computed(() => {
    return props.row.fields?.jsonContent?.bottomGradient
      ? props.row.fields.jsonContent.bottomGradient
      : '';
  });

  const onElementObserved = (entries) => {
    if (entries) {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'observable-in-view',
          entry.isIntersecting
        );
      });
    }
  };

  const startParallaxAnimation = (elementTop, elementBottom) => {
    return elementTop <= window.innerHeight / 2 && elementBottom >= 0;
  };

  const animate = (type, parentElement, targetElement) => {
    if (type === 'fade-out-parallax') {
      if (
        startParallaxAnimation(
          parentElement.getBoundingClientRect().top,
          parentElement.getBoundingClientRect().bottom
        )
      ) {
        targetElement.style.transform = `translateY(${Math.floor(
          backgroundMediaYStartPosition.value +
            (parentElement.getBoundingClientRect().top -
              Math.floor(window.innerHeight / 2)) /
              parallaxVelocity.value
        )}px)`;
      }
      if (parentElement.getBoundingClientRect().top <= 0) {
        targetElement.style.opacity =
          (parentElement.offsetHeight +
            parentElement.getBoundingClientRect().top) /
          parentElement.offsetHeight;
      }
    }

    if (type === 'fade-out') {
      targetElement.style.opacity =
        (parentElement.offsetHeight +
          parentElement.getBoundingClientRect().top) /
        parentElement.offsetHeight;
    }
  };

  const mediaType = computed(() => {
    return props.row.fields?.backgroundImage
      ? props.row.fields.backgroundImage.sys.contentType.sys.id
      : '';
  });

  const mediaSelector = computed(() => {
    return mediaType.value === 'video' ? 'video' : 'img.rwbm-background-img';
  });

  const onWindowScroll = () => {
    if (
      document.querySelector(
        `.observable-in-view[data-animate-type='${animateType.value}'] ${mediaSelector.value}`
      )
    ) {
      animate(
        animateType.value,
        document.querySelector(
          `.observable-in-view[data-animate-type='${animateType.value}']`
        ),
        document.querySelector(
          `.observable-in-view[data-animate-type='${animateType.value}'] ${mediaSelector.value}`
        )
      );
    }
  };

  const createObserver = () => {
    observer = new IntersectionObserver(onElementObserved, {
      root: document.window,
      threshold: 0,
    });
  };

  onMounted(() => {
    if (
      animateType.value === 'fade-out' ||
      animateType.value === 'fade-out-parallax'
    ) {
      const targets = document.querySelectorAll('div[data-animate-type]');
      createObserver();
      for (const target of targets) {
        if (target.getAttribute('data-has-media-position') === 'true') {
          if (target.querySelector(mediaSelector.value)) {
            target.querySelector(mediaSelector.value).style.transform =
              `translateY(${backgroundMediaYStartPosition.value}px)`;
          }
        }
        observer.observe(target);
      }
      window.addEventListener('scroll', onWindowScroll);
      onWindowScroll();
    }
  });

  onUnmounted(() => {
    if (
      animateType.value === 'fade-out' ||
      animateType.value === 'fade-out-parallax'
    ) {
      const targets = document.querySelectorAll('div[data-animate-type]');
      for (const target of targets) {
        observer.unobserve(target);
      }
      window.removeEventListener('scroll', onWindowScroll);
    }
  });
</script>

<style lang="scss" scoped>
  .bg--image-overflow {
    @apply absolute left-0 top-0 w-full;
    height: calc(100% + 120px);
  }
  .hero--tall {
    height: 640px;
    @media (max-width: theme('screens.md')) {
      height: 525px;
    }
  }
  .hero--tall-left {
    @apply items-end;
    .lg\:container {
      padding: 0 40px;
      @screen md {
        @apply px-3;
        max-width: 1300px;
      }
      & > div {
        padding-bottom: 110px;
        @screen sm {
          padding-bottom: 120px;
        }
        @screen md {
          padding-bottom: 130px;
        }
      }
    }
    @media (max-width: theme('screens.md')) {
      height: 595px;
    }
    @media (max-width: theme('screens.sm')) {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .column {
      @screen lg {
        @apply w-5/12;
      }
    }
  }
  .hero--top {
    @screen md {
      p:not([class]) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .hero--narrow {
    height: 360px;
    @media (max-width: theme('screens.md')) {
      height: 320px;
    }
  }
  .hero--video {
    & > div {
      @apply absolute left-0 top-0 h-full w-full overflow-hidden;
    }
    :deep() {
      .video__container {
        @apply absolute left-0 top-0 h-full w-full overflow-hidden;
      }
      video {
        @apply absolute min-h-full w-full max-w-none object-cover;
      }
    }
  }
  .hero--fade-out {
    @apply flex-wrap text-center;
    &.hero--fade-out-video {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 12%
      );
    }
    .hero--video {
      z-index: -1;
    }
    h1 {
      @screen md {
        font-size: 60px;
        line-height: 70px;
        font-weight: 500;
      }
    }
    @screen md {
      @apply flex-nowrap text-left;
      &.hero--fade-out-video {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 20%
        );
      }
      .hero--video video {
        width: auto;
        max-height: 100%;
        float: right;
        left: auto;
      }
    }
    @media (max-width: theme('screens.md')) {
      height: auto;
      .hero--video > div {
        @apply relative;
      }
      .hero--video {
        @apply relative;
      }
      .hero--video::before {
        @apply absolute left-0 top-0 z-10 h-full w-full;
        content: '';
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 12%
        );
      }
      .hero--video video {
        width: 160%;
        left: auto;
        top: 0;
        transform: none;
        float: right;
      }
      .flex:first-of-type {
        @apply pb-1;
      }
    }
  }
  .hero--contact {
    padding-bottom: 75px;
    img {
      margin-bottom: 50px;
    }
    @media (min-width: theme('screens.lg')) {
      img {
        top: 160px;
        position: absolute;
      }
    }
    h1 {
      @apply mb-2 mt-1;
    }
    @media (max-width: theme('screens.lg')) {
      background: theme('colors.blue.lighter');
      padding-bottom: 0;
      & .column:last-of-type {
        margin-top: 50px;
      }
      h1 {
        @apply mt-0;
      }
    }
    @media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
      .column {
        @apply w-12/12;
        &:nth-of-type(4) {
          order: -1;
        }
        &:nth-of-type(1) {
          order: -2;
        }
      }
      .btn {
        @apply mb-3;
      }
      .flex {
        @apply pt-2;
      }
    }
  }
  .hero--contact-reverse {
    padding-bottom: 75px;
    img {
      margin-bottom: 50px;
    }
    h1 {
      @apply mb-2 mt-1;
    }
    @media (max-width: theme('screens.md')) {
      background: linear-gradient(
        to bottom,
        theme('colors.blue.lighter') 66%,
        theme('colors.white') 33%
      );
      padding-bottom: 0;
      & .column:last-of-type {
        margin-top: 50px;
      }
      h1 {
        @apply mt-0;
      }
    }
  }
</style>
